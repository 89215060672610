import { createSlice } from '@reduxjs/toolkit';

const linkedOrganization = createSlice({
    name: 'linkedOrganization',
    initialState: {
        linkedOrganizations: undefined,
        linkedOrganizationDetails: {
            id: null,
            email: undefined,
            type: [],
            organizationMail: undefined
        },
        searchResult: [],
    },
    reducers: {
        setLinkedOrganizations(state, action) {
            state.linkedOrganizations = action.payload
        },
        setLinkedOrganizationDetails(state, action) {
            state.linkedOrganizationDetails = action.payload
        },
        setSearchResult(state, action) {
            state.searchResult = action.payload
        },
    }
})
export const linkedOrganizationActions = linkedOrganization.actions;
export default linkedOrganization;