import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentStep: 0,
  isLoading: false,
  selectedSeconds: 90,
  selectedSPM: '',
  isPaused: false,
};

const pumpPrimingSlice = createSlice({
  name: 'pumpPriming',
  initialState,
  reducers: {
    decreaseStep(state) {
      state.currentStep = state.currentStep - 1;
    },
    increaseStep(state) {
      state.currentStep = state.currentStep + 1;
    },
    resetState() {
      return { ...initialState };
    },
    setCurrentStep(state, action) {
      state.currentStep = action.payload
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setIsPaused(state, action) {
      state.isPaused = action.payload;
    },
    setSeconds(state, action) {
      state.selectedSeconds = Number(action.payload);
    },
    setSPM(state, action) {
      state.selectedSPM = action.payload;
    }
  },
});

export const pumpPrimingActions = pumpPrimingSlice.actions;

export default pumpPrimingSlice;
