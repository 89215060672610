import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    selectedPlantObj: {},
    ssid: '',
    value: null,
    pwd: '',
    mac: '',
    plants: [],
    loading: true,
    serverDetails: {},
};

const wifiConnectionSlice = createSlice({
    name: 'wifiConnection',
    initialState,
    reducers: {

        setSelectedPlantObj(state, action) {
            state.selectedPlantObj = action.payload;
        },
        setValue(state, action) {
            state.value = action.payload;
        },
        setSsid(state, action) {
            state.ssid = action.payload;
        },
        setPwd(state, action) {
            state.pwd = action.payload;
        },
        setMac(state, action) {
            state.mac = action.payload;
        },
        setPlants(state, action) {
            state.plants = action.payload;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setServerDetails(state, action) {
            state.serverDetails = action.payload;
        },
    }
});

export const wifiConnectionAction = wifiConnectionSlice.actions;

export default wifiConnectionSlice;