import { createSlice } from '@reduxjs/toolkit';

const softwareUpdateSlice = createSlice({
    name: 'softwareUpdate',
    initialState: {
        firmwareApiCheck: false,
        firmwareError: false,
        firmwares: [],
        isRunning: false,
        isToUpdate: false,
        isUpdating: false,
        progress: 0,
        updatesToDo: [],

        isAutomatic: false,
        openUpdatedModal: false,
        selectedUpdate: null,
        openConfirm: false
    },
    reducers: {
        resetState(state) {
            state.isUpdating = false;
            state.isRunning = false;
            state.progress = 0;
        },
        setProgress(state, action) {
            state.progress = action.payload;
        },
        setIsAutomatic(state, action) {
            state.isAutomatic = action.payload;
        },
        setIsRunning(state, action) {
            state.isRunning = action.payload;
        },
        setIsToUpdate(state, action) {
            state.isToUpdate = action.payload;
        },
        setIsUpdating(state, action) {
            state.isUpdating = action.payload;
        },
        setUpdatesToDo(state, action) {
            state.updatesToDo = action.payload;
        },
        setFirmwares(state, action) {
            state.firmwares = action.payload;
        },
        setFirmwareApiCheck(state, actions) {
            state.firmwareApiCheck = actions.payload
        },
        setFirmwareError(state, action) {
            state.firmwareError = action.payload;
        },
        setFirmwareStatus(state, action) {
            state.isToUpdate = action.payload.toUpdate;
            state.isUpdating = action.payload.updating;
            state.isRunning = action.payload.running;
        },
        setOpenConfirm(state, action) {
            state.openConfirm = action.payload;
        },
        setOpenUpdatedModal(state, action) {
            state.openUpdatedModal = action.payload;
        },
        setSelectedUpdate(state, action) {
            state.selectedUpdate = action.payload;
        },
        setUpdatingStatus(state, action) {
            state.isUpdating = action.payload.updating;
            state.isRunning = action.payload.running;
            state.openUpdatedModal = action.payload.open;
            state.isToUpdate = action.payload.update;
        },
        setUpdatesToDoAndFirmwares(state, action) {
            state.updatesToDo = action.payload.updates;
            state.firmwares = action.payload.firmwares
        },
        resetFirmwareAndUpdatesToDo(state) {
            state.firmwares = [];
            state.updatesToDo = [];
        },
        resetProgressAndCloseModal(state, action) {
            state.progress = action.payload.progress;
            state.openConfirm = action.payload.confirm;;
            state.selectedUpdate = action.payload.selected
        },
        resetUpdatedModalAndError(state) {
            state.openUpdatedModal = false;
            state.firmwareError = false;
        },
    },
});

export const softwareUpdateActions = softwareUpdateSlice.actions;
export default softwareUpdateSlice;