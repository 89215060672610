import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    backfromMotorPumpPriming: false,
    currentStep: 0,
    goToPressure: false,
    isLoading: false,
    pressureValue: '',
    openModal: false,
};

const pumpPressureSlice = createSlice({
    name: 'pumpPressure',
    initialState,
    reducers: {
        decreaseStep(state) {
            state.currentStep = state.currentStep - 1;
        },
        increaseStep(state) {
            state.currentStep = state.currentStep + 1;
        },
        setBackFromMotorPumpPriming(state, action) {
            state.backfromMotorPumpPriming = action.payload
        },
        setCurrentStep(state, action) {
            state.currentStep = action.payload
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload;
        },
        setPressureValue(state, action) {
            state.pressureValue = action.payload;
        },
        setOpenModal(state, action) {
            state.openModal = action.payload
        },
        setGoToPressure(state, action) {
            state.goToPressure = action.payload;
        }
    }
});

export const pumpPressureActions = pumpPressureSlice.actions;

export default pumpPressureSlice;
