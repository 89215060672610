import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    calpulses: null,
    isCompleted: false,
    isPlaying: false,
    isPaused: false,
    isLoading: false,
    openModal: false,
}

const timerCounterSlice = createSlice({
    name: 'timerCounter',
    initialState,
    reducers: {
        resetState() {
            return {
                isCompleted: false,
                isPlaying: false,
                isPaused: false,
                isLoading: false,
            };
        },
        setCalpulses(state, action) {
            state.calpulses = action.payload;
        },
        setIsCompleted(state, action) {
            state.isCompleted = action.payload;
        },
        setIsPlaying(state, action) {
            state.isPlaying = action.payload;
        },
        setIsPaused(state, action) {
            state.isPaused = action.payload;
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload;
        },
        setOpenModal(state, action) {
            state.openModal = action.payload;
        }
    }
});

export const timerCounterActions = timerCounterSlice.actions;

export default timerCounterSlice;
