import { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

import { useSelector } from 'react-redux';

// Components
import Layout from './components/layout/Layout/Layout';
import FullPageLoader from 'components/common/FullPageLoader/FullPageLoader';
import ProtectedRoute from 'components/hoc/ProtectedRoute';
import SuspenseComponent from 'components/hoc/SuspenseComponent';

// Styles
import './styles/styles.scss';

// Util
import { routes } from 'util/routes';

// Route lazy views
const Home = lazy(() => import('view/Home/Home'));
const Page404 = lazy(() => import('view/Page404/Page404'));
const Notifications = lazy(() => import('view/Notifications/Notifications'));
const PlantRoutes = lazy(() => import('routes/PlantRoutes'));
const PeopleRoutes = lazy(() => import('routes/PeopleRoutes'));
const LoginRoutes = lazy(() => import('routes/LoginRoutes'));


function App() {
  const { cognitoToken } = useSelector((state) => state.login);

  return (
    <Layout>
      <Routes>
        <Route element={<ProtectedRoute variable={cognitoToken} to={routes.login} />}>
          <Route path={routes.home} element={<SuspenseComponent Component={Home} loader={<FullPageLoader />} />} exact />
          <Route path={routes.notifications} element={<SuspenseComponent Component={Notifications} loader={<FullPageLoader />} />} exact />
          <Route path={routes.plants + '/*'} element={<SuspenseComponent Component={PlantRoutes} loader={<FullPageLoader />} />} exact />
          <Route path={routes.people + '/*'} element={<SuspenseComponent Component={PeopleRoutes} loader={<FullPageLoader />} />} exact />
        </Route>
        <Route path={`${routes.login}/*`} element={<SuspenseComponent Component={LoginRoutes} loader={<FullPageLoader />} />} />
        <Route path='*' element={<SuspenseComponent Component={Page404} loader={<FullPageLoader />} />} />
      </Routes>
    </Layout >
  );
}

export default App;
