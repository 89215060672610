import { createSlice } from '@reduxjs/toolkit';

// Util
import { constants } from 'util/constants';

const plantsSlice = createSlice({
  name: 'plants',
  initialState: {
    activeBtn: false,
    associatedPlants: [],
    confirmMemberModalOpen: false,
    confirmOwnerModalOpen: false,
    currentDeviceData: {},
    currentDeviceId: {},
    currentDeviceStatus: {},
    currentPlantData: {},
    currentPlantDevices: [],
    currentPlantUser: {},
    currentPlantMembers: [],
    currentPlantId: 0,
    currentPlantStatus: '',
    currentPlantTimezone: '',
    currentPlantTimezoneMinutes: '',
    isCompleted: true,
    isLoading: true,
    isReload: false,
    inputField: '',
    missingFields: [],
    modalOpen: false,
    newMemberEmail: null,
    ownerModalOpen: false,
    plants: [],
    newPlant: {
      selectedTag: false,
      error: false
    },
    selectedDevice: {},
    showDeleteModal: false,
  },

  reducers: {
    updatePlants(state, action) {
      const newItem = action.payload;
      const copyOfPlants = [...state.plants];
      let existingItemIndex = copyOfPlants.findIndex((item) => item.id === newItem.id) || copyOfPlants.findIndex((item) => item.name === newItem.name);

      if (existingItemIndex === -1) {
        state.plants = [...state.plants, newItem];
      } else {
        copyOfPlants[existingItemIndex] = newItem;
        state.plants = copyOfPlants;
      }
    },

    updatePlantData(state, action) {
      const id = action.payload.id;
      const fieldKey = action.payload.key;
      const newValue = action.payload.value;

      let existingItem = state.plants.find((item) => item.id.toString() === id.toString());

      if (existingItem) {
        existingItem[fieldKey] = newValue;
      }
    },
    updatePlantStatus(state, action) {
      state.currentPlantStatus = action.payload;
    },
    updatePlantDevices(state, action) {
      state.currentPlantId = action.payload.plantId;
      state.currentPlantDevices = action.payload.devices;
    },
    updatePlantUsers(state, action) {
      state.currentPlantId = action.payload.plantId;
      state.currentPlantUser = action.payload.user;
      state.currentPlantMembers = action.payload.members;
    },
    replacePlants(state, action) {
      state.plants = action.payload;
    },
    selectCurrentPlant(state, action) {
      state.currentPlantData = action.payload;
      state.currentPlantMembers = action.payload.members
      state.currentPlantId = action.payload.id;
      state.currentPlantStatus = action.payload.status;
      state.currentPlantDevices = action.payload.devices;
    },
    resetCurrentPlant(state) {
      state.currentPlantData = {};
      state.currentPlantId = '';
      state.currentPlantStatus = '';
      state.currentPlantTimezone = '';
      state.currentPlantMembers = [];
    },
    removePlantById(state, action) {
      const filteredPlants = [...state.plants].filter(
        ({ id }) => id.toString() !== action.payload.toString(),
      );
      state.plants = filteredPlants;
    },
    selectCurrentDevice(state, action) {
      state.currentDeviceData = action.payload;
      state.currentDeviceId = action.payload.id;
      state.currentDeviceStatus = action.payload.status;
    },
    resetCurrentDevice(state) {
      state.currentDeviceData = {};
      state.currentDeviceId = '';
      state.currentDeviceStatus = '';
    },
    updateDeviceStatus(state, action) {
      state.currentDeviceStatus = action.payload;
    },
    updateDeviceData(state, action) {
      const { key, value, plantId, deviceId } = action.payload;
      let existingPlant = state.plants.find((item) => {
        item.id && item.id.toString() === plantId.toString()
      });

      // Update plants array
      if (existingPlant && existingPlant.devices) {
        const existingDevice = existingPlant.devices.find(
          (_device) => _device.id.toString() === deviceId.toString(),
        );

        if (existingDevice) {
          if (key === constants.configuration) {
            existingDevice.configuration = {
              ...existingDevice.configuration,
              ...value,
            };
          } else {
            existingDevice[key] = value;
          }
        }
      }

      // Update current devices array
      let existingPlantDevice = state.currentPlantDevices.find(
        (item) => item.id.toString() === deviceId.toString(),
      );

      if (existingPlantDevice) {
        if (key === constants.configuration) {
          existingPlantDevice.configuration = {
            ...existingPlantDevice.configuration,
            ...value,
          };
        } else {
          existingPlantDevice[key] = value;
        }
      }

      // Update current device data
      let existingCurrentDevice = state.currentDeviceId.toString() === deviceId.toString();

      if (existingCurrentDevice) {
        if (key === constants.configuration) {
          state.currentDeviceData.configuration = {
            ...state.currentDeviceData.configuration,
            ...value,
          };
        } else {
          state.currentDeviceData[key] = value;
        }
      }

      if (key === constants.status) {
        state.currentDeviceStatus = value;
      }
    },
    updateDeviceName(state, action) {
      const { plantId, deviceId, name } = action.payload;

      const existingPlant = state.plants.find((item) => item.id && item.id.toString() === plantId.toString());

      if (existingPlant && existingPlant.devices) {
        const existingDevice = existingPlant.devices.find((_device) => _device.id.toString() === deviceId.toString());

        if (existingDevice) {
          existingDevice.name = name;
        }
      }

      const existingPlantDevice = state.currentPlantDevices.find((item) => item.id.toString() === deviceId.toString());

      if (existingPlantDevice) {
        existingPlantDevice.name = name;
      }

      const existingCurrentDevice = state.currentDeviceId.toString() === deviceId.toString();

      if (existingCurrentDevice) {
        state.currentDeviceData.name = name;
      }
    },
    setActiveBtn(state, action) {
      state.activeBtn = action.payload;
    },
    setAssociatedPlants(state, action) {
      state.associatedPlants = action.payload;
    },
    setAssociatedPlantsLoading(state, action) {
      state.associatedPlants = action.payload;
      state.isLoading = false;
    },
    setConfirmMemberModalOpen(state, action) {
      state.confirmMemberModalOpen = action.payload;
    },
    setConfirmOwnerModalOpen(state, action) {
      state.confirmOwnerModalOpen = action.payload;
    },
    setIsCompleted(state, action) {
      state.isCompleted = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setIsReload(state, action) {
      state.isReload = action.payload;
    },
    setModalOpen(state, action) {
      state.modalOpen = action.payload;
    },
    setNewMemberEmail(state, action) {
      state.newMemberEmail = action.payload;
    },
    setNewPlant(state, action) {
      state.newPlant = action.payload;
    },
    setOwnerModalOpen(state, action) {
      state.ownerModalOpen = action.payload;
    },
    setMalfunction(state, action) {
      state.malfunction = action.payload;
    },
    setCurrentPlantTimezone(state, action) {
      state.currentPlantTimezone = action.payload;
    },
    setCurrentPlantTimezoneMinutes(state, action) {
      state.currentPlantTimezoneMinutes = action.payload;
    },
    setRunning(state, action) {
      state.running = action.payload;
    },
    setInputField(state, action) {
      state.inputField = action.payload;
    },
    setShowDeleteModal(state, action) {
      state.showDeleteModal = action.payload;
    },
    setSelectedTag(state, action) {
      state.selectedTag = action.payload;
    },
    setSelectedDevice(state, action) {
      state.selectedDevice = action.payload;
    },
    setConfirmSelectOwnerModal(state, action) {
      state.ownerModalOpen = action.payload.ownerModalOpen;
      state.confirmOwnerModalOpen = action.payload.confirmAsOwner;
      state.confirmMemberModalOpen = action.payload.confirmNewOwner;
    },
    setMissingFields(state, action) {
      state.missingFields = action.payload;
    },
    resetNewPlant: (state) => {
      return {
        ...state,
        newPlant: {
          selectedTag: false,
          error: false
        }
      };
    },
  }
});

export const plantsActions = plantsSlice.actions;

export default plantsSlice;
