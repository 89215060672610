import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    userData: {},
    isLoggedIn: false,
  },
  reducers: {
    login(state, action) {
      state.userData = action.payload.userData;
      state.isLoggedIn = true;
    },
    logout(state) {
      state.userData = {};
      state.isLoggedIn = false;
    },
  },
});

export const userActions = userSlice.actions;

export default userSlice;
