import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  advPropMax: false,
  advPropMin: false,
  allEnabled: false,
  changeWifi: false,
  connectionValue: 0,
  currentStep: 0,
  dosingEnabled: false,
  enabledExternalSignal1: false,
  enabledExternalSignal2: false,
  enableOfa: false,
  enableAlarm: false,
  hour: '00:01',
  isLoading: false,
  isSuccesfully: false,
  min: null,
  max: null,
  minLimit: null,
  maxLimit: null,
  minRotaz: 15,
  maxRotaz: 100,
  ofaMinutes: 0,
  openOfaModal: false,
  pulsevol: 0,
  reboot: false,
  restart: false,
  referenceTemp: null,
  sliderActive: false,
  soundDuration: '',
  startupDelay: 0,
  tempValue: 0,
  temp: null,
  tempCoefficent: null,
};

const deviceSettingsSlice = createSlice({
  name: 'deviceSettings',
  initialState,
  reducers: {
    decreaseStep(state) {
      state.currentStep--;
    },
    increaseStep(state) {
      state.currentStep++;
    },
    resetState() {
      return { ...initialState };
    },
    resetStepState() {
      return { currentStep: 0 };
    },
    setAllEnabled(state, action) {
      state.allEnabled = action.payload;
    },
    setChangeWifi(state, action) {
      state.changeWifi = action.payload;
    },
    setConnectionValue(state) {
      state.connectionValue = state.connectionValue === 1 ? 0 : 1;
    },
    setDosingEnabled(state, action) {
      state.dosingEnabled = action.payload;
    },
    setEnabledExternalSignal1(state, action) {
      state.enabledExternalSignal1 = action.payload;
    },
    setEnabledExternalSignal2(state, action) {
      state.enabledExternalSignal2 = action.payload;
    },
    setEnabledOfa(state, action) {
      state.enableOfa = action.payload;
    },
    setEnabledAlarm(state, action) {
      state.enableAlarm = action.payload;
    },
    setHour(state, action) {
      state.hour = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setIsSucecsfully(state, action) {
      state.isSuccesfully = action.payload;
    },
    setMin(state, action) {
      state.min = Number(action.payload);
    },
    setMinLimit(state, action) {
      state.minLimit = parseInt(action.payload);
    },
    setMinRotaz(state, action) {
      state.minRotaz = Number(action.payload);
    },
    setMax(state, action) {
      state.max = Number(action.payload);
    },
    setMaxLimit(state, action) {
      state.maxLimit = parseInt(action.payload);
    },
    setMaxRotaz(state, action) {
      state.maxRotaz = Number(action.payload);
    },
    setOfaMinutes(state, action) {
      state.ofaMinutes = action.payload;
    },
    setOpenOfaModal(state, action) {
      state.openOfaModal = action.payload;
    },
    setPulsevol(state, action) {
      state.pulsevol = Number(action.payload);
    },
    setRestart(state, action) {
      state.restart = action.payload;
    },
    setReboot(state, action) {
      state.reboot = action.payload;
    },
    setReferenceTemp(state, action) {
      state.referenceTemp = Number(action.payload);
    },
    setSliderActive(state, action) {
      state.sliderActive = action.payload;
    },
    setSoundDuration(state, action) {
      state.soundDuration = action.payload;
    },
    setStartupDelay(state, action) {
      state.startupDelay = Number(action.payload);
    },
    setTempCoefficent(state, action) {
      state.tempCoefficent = Number(action.payload);
    },
    setTemp(state, action) {
      state.temp = Number(action.payload);
    },
    setTempValue(state) {
      state.tempValue = state.tempValue === 1 ? 0 : 1;
    },
    setAdvPropMax(state, action) {
      state.advPropMax = action.payload;
    },
    setAdvPropMin(state, action) {
      state.advPropMin = action.payload;
    }
  },
});

export const deviceSettingsActions = deviceSettingsSlice.actions;

export default deviceSettingsSlice;
