import instance from '../index';
import loginInstance from '../loginIndex';

const ACTIVATE_USER = 'activate-user'
const ASSOCIATIONS = 'associations';
const AUTH = 'auth';
const CHECK_SIGNUP_STATUS = 'check-signup-status'
const CHANGE = 'change'
const GET_TOKEN = 'get-auth-token';
const INVITATIONS = 'invitations';
const LEAVE = 'leave';
const LOGGED = 'logged';
const NOTIFICATION_SETITNGS = 'settings'
const ORGANIZATIONS = 'organizations';
const ORGANIZATION_PLANTS = 'organization-plants';
const REMOVE = 'remove';
const ROLE = 'role'
const SIGNUP = 'sign-up';
const UPDATE_PLANT_PERMISSION = 'update-plant-permission';
const USERS = 'users';

const UserApi = {

    /**
     * Api to active user after confirmation code
     */
    activateUser({ payload }) {
        return instance.post(`/${USERS}/${ACTIVATE_USER}`, payload)
    },

    /**
     * Api to change user role within an organization
     */
    changeOrgMemberRole(organizationId, userId) {
        return instance.put(`/${ORGANIZATIONS}/${organizationId}/${CHANGE}-${ROLE}/${userId}`)
    },

    /**
     * Api to check the  signup status of user 
     */
    checkCognitoStatus({ email }) {
        return instance.get(`/${USERS}/${CHECK_SIGNUP_STATUS}`, { params: { email: email } })
    },

    /**
   * Api to delete member from organization
   */
    deleteMemberFromOrganization({ userId, organizationId }) {
        return instance.put(`/${ORGANIZATIONS}/${organizationId}/${REMOVE}/${userId}`)
    },

    /**
    * Api to delete the organization
    */
    deleteOrganization({ organizationId }) {
        return instance.delete(`/${ORGANIZATIONS}/${organizationId}`)
    },
    /**
    * Api to delete an existing user 
    */
    deleteUser(userId) {
        return instance.delete(`/${USERS}/${userId}`)
    },

    /**
    * Api used to get the auth token
    */
    getAuthToken({ token }) {
        return loginInstance.post(
            `/${AUTH}/${GET_TOKEN}`,
            token,
            { headers: { Authorization: `Bearer ${token.rawToken}` } }
        );
    },

    /**
    * Api to get invite info
    */
    getInvitations({ token }) {
        return instance.get(`/${INVITATIONS}/${token}`);
    },

    /**
    * api to get info and member of organization
    */
    getOrganizations({ id }) {
        return instance.get(`/${ORGANIZATIONS}/${id}`);
    },
    /**
    * Api to get plants associated to a user inside an organization
    */
    getOrganizationPlant(userId, organizationId) {
        return instance.get(`/${USERS}/${userId}/${ORGANIZATION_PLANTS}/${organizationId}`);
    },

    /**
    * Api used to get the user Details
    */
    getUserDetails() {
        return instance.get(`/${USERS}/${LOGGED}`);
    },

    /**
     * Api used to leave an organization as memeber 
     */
    memberLeaveOrganization(organizationId) {
        return instance.put(`/${ORGANIZATIONS}/${organizationId}/${LEAVE}`)
    },
    /**
     * Api used to create invite admin of an organization or member organization
     */
    sendInvitations({ payload }) {
        return instance.post(`/${INVITATIONS}`, payload);
    },

    /**
     * Api used to sign-up a new user
     */
    signUp({ payload }) {
        return instance.post(`/${USERS}/${SIGNUP}`, payload);
    },

    /**
    * Api to update organization data
     */
    updateOrganizations({ id, payload }) {
        return instance.put(`/${ORGANIZATIONS}/${id}`, payload);
    },

    /**
     * Api to update userInfo
     */
    updateUsersInfo({ userId, payload }) {
        return instance.put(`/${USERS}/${userId}`, payload);
    },

    /**
     *  Api to update user Notification Settings
     * 
     */
    updateUserNotificationSettings({ userId, payload }) {
        return instance.put(`/${USERS}/${userId}/${NOTIFICATION_SETITNGS}`, payload);
    },

    /**
    * Api to update user permission on plant 
    */
    updateUserPlantPermission({ userId, organizationId, payload }) {
        return instance.put(`/${USERS}/${userId}/${ASSOCIATIONS}/${UPDATE_PLANT_PERMISSION}/${organizationId}`, payload);
    },
}
export default UserApi;
