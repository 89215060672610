import { Suspense } from 'react';
import PropTypes from 'prop-types';
const SuspenseComponent = ({ Component, loader }) => {

    return (
        <Suspense fallback={loader}>
            <Component />
        </Suspense>
    )
}
SuspenseComponent.propTypes = {
    Component: PropTypes.object,
    loader: PropTypes.object,
};
export default SuspenseComponent