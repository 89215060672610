import React from 'react';

// Components
import BaseLoader from 'components/base/BaseLoader/BaseLoader';

// Styles
import styles from './FullPageLoader.module.scss';

const FullPageLoader = () => {
  return (
    <div className={styles.loader}>
      <BaseLoader />
    </div>
  );
};

export default FullPageLoader;
