import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    channel: null,
    channelSelected: null,
    currentStep: 0,
    measure1: null,
    measure2: null,
    measure3: null,
    senseModels: [],
    priority: '0',
    range1: null,
    range2: null,
    range3: null,
}

const channelSlice = createSlice({
    name: 'channel',
    initialState,
    reducers: {
        changeStep(state, action) {
            state.currentStep = action.payload;
        },
        decreaseStep(state) {
            state.currentStep = state.currentStep - 1;
        },
        increaseStep(state) {
            state.currentStep = state.currentStep + 1;
        },
        resetState() {
            return { ...initialState };
        },
        setChannel(state, action) {
            state.channel = action.payload;
        },
        setChannelSelected(state, action) {
            state.channelSelected = action.payload;
        },
        setMeasure1(state, action) {
            state.measure1 = action.payload;
        },
        setMeasure2(state, action) {
            state.measure2 = action.payload;
        },
        setMeasure3(state, action) {
            state.measure3 = action.payload;
        },
        setPriority(state, action) {
            state.priority = action.payload;
        },
        setRange1(state, action) {
            state.range1 = action.payload;
        },
        setRange2(state, action) {
            state.range2 = action.payload;
        },
        setRange3(state, action) {
            state.range3 = action.payload;
        },
        setSenseModel(state, action) {
            state.channel = action.payload.channel;
            state.senseModels = action.payload.end;
        },
    }
});

export const channelActions = channelSlice.actions;

export default channelSlice;
