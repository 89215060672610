import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    devices: {},
    parametersArray: [],
};

const parametersSlice = createSlice({
    name: 'parameters',
    initialState,
    reducers: {
        setInitialState: (state) => {
            state.parametersArray = [];
            state.devices = {}
        },
        setId: (state, action) => {
            const { deviceId } = action.payload;

            if (!state.devices[deviceId]) {
                state.devices[deviceId] = { id: deviceId };
            }
            state.devices[deviceId].id = deviceId;
        },
        setError: (state, action) => {
            const { deviceId, payload } = action.payload;

            if (!state.devices[deviceId]) {
                state.devices[deviceId] = { id: deviceId };
            }
            state.devices[deviceId].error = payload;
        },
        resetState: () => {
            return initialState;
        },
        setDevice: (state, action) => {
            const { deviceId, device } = action.payload;
            if (Object.keys(device).length === 0) {
                state.devices = {};
            } else {
                state.devices[deviceId] = { ...device };
            }
        },

        setParametersArray: (state, action) => {
            const { newParam, isParamTrend } = action.payload;

            if (Array.isArray(newParam) && newParam.length === 0) {
                state.parametersArray = action.payload.newParam;
            } else {
                const newArray = state.parametersArray.map(param => {
                    if (param.device.id === newParam.device.id && param.title === newParam.title) {
                        return newParam;
                    }
                    return param;
                });

                if (!(newParam.device.position === 2 && (newParam.title === 'Temperature' || newParam.title === 'Flow Rate') && !isParamTrend)) {
                    if (!newArray.some(param => param.device.id === newParam.device.id && param.title === newParam.title)) {
                        newArray.push(newParam);
                    }
                }

                newArray.sort((a, b) => a.device.id - b.device.id);
                return { ...state, parametersArray: newArray };
            }
        },

    }
});

export const {
    setParameter,
    setId,
    setError,
    resetState,
    setDevice,
    setParametersArray,
    setInitialState
} = parametersSlice.actions;

export default parametersSlice;
