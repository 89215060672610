import React from 'react';

// Styles
import styles from './BaseLoader.module.scss';

const BaseLoader = () => {
  return <div className={styles.baseLoader} style={{ fontSize: '2rem', color: 'white' }}></div>;
};

export default BaseLoader;
