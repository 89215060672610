import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

// Components
import BaseIcon from 'components/base/BaseIcon/BaseIcon';

// Styles
import styles from './NavItem.module.scss';

// Util
import { routes } from 'util/routes';

/**
 * Component used to display one navigation item
 * @prop {String} title
 * @prop {String} url - route that it should link to
 * @prop {Object} icon
 */
const NavItem = ({ title, url, icon }) => {
  const { pathname } = useLocation();

  return (
    <li key={url}>
      <NavLink
        to={url}
        className={({ isActive }) =>
          isActive || (pathname === routes.home && url === routes.plants)
            ? [styles.navItem, styles.navItem__active].join(' ')
            : styles.navItem
        }
      >
        <BaseIcon icon={icon} customClass={styles.navItem__icon} />
        <span className={styles.navItem__title}>{title}</span>
      </NavLink>
    </li>
  );
};

NavItem.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string.isRequired,
  icon: PropTypes.string,
};

export default NavItem;
