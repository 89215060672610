export const constants = {
  configuration: 'configuration',
  dose: 'IWDOSE',
  iw_dose: 'iw_dose',
  iw_motorPump: 'motorPump',
  iw_pool: 'iw_pool',
  iw_sense: 'iw_sense',
  motorPump: 'MOTORPUMP',
  notification: 'notification',
  pool: 'IWPOOL',
  settings: 'settings',
  status: 'status',
  user: 'user',
};
