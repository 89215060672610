import { createSlice } from '@reduxjs/toolkit';

const deviceSlice = createSlice({
    name: 'device',
    initialState: {
        allStatus: [],
        callDeviceApi: true,
        dataCal: null,
        devicesIdsArray: [],
        deviceModel: '',
        deviceName: '',
        heartbeatIsPresent: null,
        is1PumpConfiguration: false,
        is2PumpConfiguration: false,
        is3PumpConfiguration: false,
        isMultiDevice: false,
        isPumpsConfiguration: false,
        isSubDevice: false,
        isThirdDevice: false,
        multiDeviceId: '',
        openStatusModal: false,
        parametersArray: [],
        parametersErrors: [],
        qualEl: null,
        status: 'none',
        statusObject: {},
        successModalOpen: false
    },
    reducers: {
        resetDeviceState(state) {
            state.allStatus = [];
            state.callDeviceApi = true;
            state.isMultiDevice = false;
            state.isSubDevice = false;
            state.statusObject = {};
        },
        setAllStatus(state, action) {
            state.allStatus = action.payload;
        },
        setCallDeviceApi(state, action) {
            state.callDeviceApi = action.payload;
        },
        setData(state, action) {
            state.dataCal = action.payload.data;
            state.qualEl = action.payload.value;
        },
        setDeviceModel(state, action) {
            state.deviceModel = action.payload;
        },
        setDeviceName(state, action) {
            state.deviceName = action.payload;
        },
        setDeviceStatus(state, action) {
            state.status = action.payload;
        },
        setHeartbeatIsPresent(state, action) {
            state.heartbeatIsPresent = action.payload;
        },
        setIsMultiDevice(state, action) {
            state.isMultiDevice = action.payload;
        },
        setIsPumpsConfiguration(state, action) {
            state.isPumpsConfiguration = action.payload;
        },
        setIs1PumpConfiguration(state, action) {
            state.is1PumpConfiguration = action.payload;
        },
        setIs2PumpConfiguration(state, action) {
            state.is2PumpConfiguration = action.payload;
        },
        setIs3PumpConfiguration(state, action) {
            state.is3PumpConfiguration = action.payload;
        },
        setIsSubDevice(state, action) {
            state.isSubDevice = action.payload;
        },
        setIsThirdDevice(state, action) {
            state.isThirdDevice = action.payload;
        },
        setMultiDeviceId(state, action) {
            state.multiDeviceId = action.payload;
        },
        setMultipleState(state, action) {
            state.isMultipool = action.payload.multi;
            state.isPumpsConfiguration = action.payload.pumpsConf;
            state.is1PumpConfiguration = action.payload.firstPumpConf;
            state.is2PumpConfiguration = action.payload.secondPumpConf;
            state.is3PumpConfiguration = action.payload.thirdPumpConf;
        },
        setQualEl(state, action) {
            state.qualEl = action.payload;
        },

        setOpenStatusModal(state, action) {
            state.openStatusModal = action.payload;
        },
        setStatusObject(state, action) {
            state.statusObject = action.payload;
        },
        setSuccessModalOpen(state, action) {
            state.successModalOpen = action.payload;
        },
    },
});

export const deviceActions = deviceSlice.actions;

export default deviceSlice;
