import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// Components
import Navigation from '../Navigation/Navigation';

import styles from './Layout.module.scss';

/**
 * Component used to display page layout witht the bottom navigation
 */
const Layout = ({ children }) => {
  const isNavigationDisplayed = useSelector((state) => state.app.navigationDisplayed);

  return (
    <div className={styles.layout}>
      {isNavigationDisplayed ? <div className={styles.layout__content}>{children}</div> : children}

      {isNavigationDisplayed && <Navigation />}
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.any.isRequired,
};
export default Layout;
