import { createSlice } from '@reduxjs/toolkit';

const operativeModesSlice = createSlice({
    name: 'operativeModes',
    initialState: {
        isLoading: false,
        checkedModality: {},
        inputErrors: {},
        isInvalid: false,
        modes: {},
        selectedValue: null,
    },
    reducers: {
        setCheckedModality(state, action) {
            state.checkedModality = action.payload
        },
        setInputErrors(state, action) {
            const { type, index, value } = action.payload;
            state.inputErrors = {
                ...state.inputErrors,
                [type]: {
                    ...state.inputErrors[type],
                    [index]: value,
                },
            };
        },
        setIsInvalid(state, action) {
            state.isInvalid = action.payload
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload
        },
        setModes: (state, action) => {
            state.modes = {
                ...state.modes,
                ...action.payload,
            };
        },
        setSelectedValue(state, action) {
            state.selectedValue = action.payload
        },

    },
});

export const operativeModesActions = operativeModesSlice.actions;

export default operativeModesSlice;
